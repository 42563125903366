import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Input,
  Radio,
  TextField,
  Typography,
  FormGroup,
  RadioGroup,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { getRequest } from "../../utils/httpHelpers";
import Dropzone from "react-dropzone";
import { popularCurrencies } from "../../constants";
import { popularTimezones } from "../../constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: theme.spacing(30),
  },
  formControlButton: {
    marginTop: theme.spacing(2),
  },
  formCheckBox: {
    textAlign: "left",
  },
  dropzoneButton: (props) => {
    return {};
  },
  button: {
    marginBottom: theme.spacing(1),
  },
  uploadedFileContent: {
    textAlign: "center",
    fontSize: "10px",
  },
  resumeBox: (props) => {
    let color = "grey";
    switch (props.dropStatus) {
      case "undropped":
        color = "grey";
        break;
      case "in_progress":
        color = theme.palette.primary.main;
        break;
      case "accepted":
        color = theme.palette.success.main;
        break;
      case "rejected":
        color = theme.palette.error.main;
        break;
      default:
      // code block
    }
    let finalCSS = {
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "10px",
      cursor: "pointer",
      border: `2px dashed ${color}`,
    };

    // if (props.dropStatus === "in_progress") {
    // finalCSS["backgroundColor"] = theme.palette.primary.main;
    // finalCSS["opacity"] = 0.3;
    // finalCSS["backgroundSize"] = "4px 4px";
    // finalCSS["backgroundImage"] =
    //   "linear-gradient(45deg, #444cf7 50%, #e5e5f7 50%)";
    // }
    return finalCSS;
  },
  resumeProgress: {
    marginLeft: theme.spacing(2),
  },
}));

export function ReczeeTimezonePicker(props) {
  const { value, onChange, title, required, disabled, className } = props;

  return (
    <ReczeeAutoComplete
      single
      fullWidth
      size="small"
      disabled={disabled}
      value={value}
      className={className}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      values={popularTimezones}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      title={title}
      required={required}
    />
  );
}

export function ReczeeCheckBox(props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
          name={props.name}
          defaultChecked={props.defaultChecked}
          color="primary"
        />
      }
      className={props.className}
      label={
        <>
          {props.label}
          {props.required && (
            <Typography
              component="span"
              color={props.disabled ? "textSecondary" : "error"}
            >
              *
            </Typography>
          )}
        </>
      }
    />
  );
}

export function ReczeeCheckBoxSelectionInput(props) {
  return (
    <FormControl component="fieldset">
      <Typography
        variant="subtitle1"
        style={{ fontWeight: "normal" }}
        color={props.disabled ? "textSecondary" : "initial"}
      >
        {props.title}
        {props.required && (
          <Typography
            component="span"
            color={props.disabled ? "textSecondary" : "error"}
          >
            *
          </Typography>
        )}
      </Typography>
      <FormGroup>
        {props.options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={<Checkbox color="primary" />}
            checked={props.value ? props.value.includes(option) : false}
            onChange={props.onChange}
            value={option}
            label={option}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export function ReczeeRadio(props) {
  return (
    <FormControlLabel
      label={props.label}
      className={props.className}
      control={<Radio name={props.name} color="primary" value={props.value} />}
    />
  );
}

export function ReczeeRadioSeletionInput(props) {
  return (
    <FormControl
      component="fieldset"
      className={props.className}
      fullWidth={props.fullWidth}
    >
      <Typography
        variant="subtitle1"
        style={{ fontWeight: "normal" }}
        color={props.disabled ? "textSecondary" : "initial"}
      >
        {props.title}
        {props.required && (
          <Typography
            component="span"
            color={props.disabled ? "textSecondary" : "error"}
          >
            *
          </Typography>
        )}
      </Typography>
      <RadioGroup
        aria-label={props.title}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      >
        {props.options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={<Radio color="primary" />}
            label={option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export function ReczeeAutoComplete(props) {
  const classes = useStyles();
  const [optionValues, setOptionValues] = React.useState(props.values || []);
  const dataFetchPath = props.dataFetchPath;
  const dataFetchParams = props.dataFetchParams || {};
  const responseMapper = props.responseMapper || ((response) => response);
  const dependencies = props.reRenderDependency ? props.reRenderDependency : [];
  let finalSortedValues = optionValues;

  if (props.sortBy) {
    finalSortedValues = optionValues.sort(props.sortBy);
  }

  React.useEffect(() => {
    if (!props.disabled && dataFetchPath) {
      getRequest(
        dataFetchPath,
        dataFetchParams,
        (data) => {
          let responseOptions = responseMapper(data);
          if (responseOptions.length === 0 && props.valuesOnEmptyResponse) {
            responseOptions = props.valuesOnEmptyResponse;
          }
          setOptionValues(responseOptions);
          if (props.callbackOnOptionLoad) {
            props.callbackOnOptionLoad(responseOptions);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }

    // @Todo(Raj): Fix this?
    // @Todo(Raj): adding props.dataFetchParams for candidate add page calls
    //             pipelines endpoint multiple times on changing designations
    // eslint-disable-next-line
  }, dependencies);

  React.useEffect(() => {
    setOptionValues(props.values || []);
  }, [props.values]);

  let inputValue = props.value;
  // Use preSelectOnlyOne carefully. Only use it for the inputs which have
  // controlled input (one with value guided with state variable)
  if (
    !props.value &&
    props.preSelectOnlyOne &&
    finalSortedValues.length === 1
  ) {
    inputValue = finalSortedValues[0];
    props.onChange(null, inputValue);
  }

  return (
    <Autocomplete
      size={props.size}
      multiple={props.single === true ? false : true}
      className={
        props.className
          ? `${props.className} ${classes.autocompleteField}`
          : classes.autocompleteField
      }
      onChange={props.onChange}
      options={finalSortedValues}
      groupBy={props.groupBy}
      disabled={props.disabled}
      filterSelectedOptions={!props.showSelectedOptions}
      disableCloseOnSelect={props.disableCloseOnSelect}
      getOptionLabel={props.getOptionLabel}
      disableClearable={props.disableClearable}
      limitTags={props.limitTags || 10}
      clearOnBlur={props.clearOnBlur}
      fullWidth={props.fullWidth}
      filterOptions={props.filterOptions}
      value={inputValue}
      freeSolo={props.freeSolo}
      selectOnFocus={props.selectOnFocus}
      getOptionSelected={
        props.getOptionSelected
          ? props.getOptionSelected
          : (option, value) => value && option.id === value.id
      }
      renderTags={props.renderTags}
      renderOption={props.renderOption}
      renderInput={(params) => (
        <>
          {props.title && (
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "normal" }}
              color={params.disabled ? "textSecondary" : "initial"}
            >
              {props.title}
              {props.required && (
                <Typography
                  component="span"
                  color={params.disabled ? "textSecondary" : "error"}
                >
                  *
                </Typography>
              )}
            </Typography>
          )}
          <TextField
            {...params}
            variant={props.variant ? props.variant : "outlined"}
            label={props.label}
            placeholder={props.placeholder}
            className={props.inputFieldClassName}
            helperText={props.helperText ? props.helperText : ""}
          />
        </>
      )}
    />
  );
}

export function ReczeeInputField(props) {
  const classes = useStyles();
  let inputVariant = props.variant || "outlined";
  let inputFieldParams = {
    multiline: props.multiline,
    placeholder: props.placeholder,
    defaultValue: props.defaultValue,
    inputProps: props.inputProps || {},
    rows: props.rows,
    id: props.id,
    onChange: props.onChange ? props.onChange : undefined,
    disabled: props.disabled,
    name: props.name,
    label: props.label,
    autoFocus: props.autoFocus,
    type: props.type ? props.type : "text",
    "aria-describedby": props.id + "-helper-text",
    endAdornment: props.endAdornment,
    variant: inputVariant,
    value: props.value,
    autoComplete: props.autoComplete ? props.autoComplete : undefined,
  };

  return (
    <FormControl
      size={props.size}
      className={
        props.className
          ? `${props.className} ${classes.formControl}`
          : classes.formControl
      }
      error={props.errorField}
      required={props.required}
      variant={inputVariant}
      fullWidth={props.fullWidth}
    >
      {props.title && (
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "normal" }}
          color={props.disabled ? "textSecondary" : "initial"}
        >
          {props.title}
          {props.required && (
            <Typography
              component="span"
              color={props.disabled ? "textSecondary" : "error"}
            >
              *
            </Typography>
          )}
        </Typography>
      )}
      {props.label && (
        <InputLabel disabled={props.disabled} htmlFor="component-outlined">
          {props.label}
        </InputLabel>
      )}
      {React.createElement(
        props.variant === "standard" ? Input : OutlinedInput,
        inputFieldParams
      )}
      {props.helperText ? (
        <FormHelperText
          disabled={props.disabled}
          id={props.id + "-helper-text"}
        >
          {props.helperText}
        </FormHelperText>
      ) : (
        ""
      )}
    </FormControl>
  );
}

export function ReczeeSelectInputField(props) {
  const classes = useStyles();
  return (
    <FormControl
      size={props.size}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      variant={props.variant ? props.variant : "outlined"}
      className={
        props.className
          ? `${props.className} ${classes.formCheckBox}`
          : classes.formCheckBox
      }
    >
      {props.title && (
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "normal" }}
          color={props.disabled ? "textSecondary" : "initial"}
        >
          {props.title}
          {props.required && (
            <Typography
              component="span"
              color={props.disabled ? "textSecondary" : "error"}
            >
              *
            </Typography>
          )}
        </Typography>
      )}
      {props.selectLabel ? (
        <InputLabel id={`select-label-${props.labelId}`}>
          {props.selectLabel}
        </InputLabel>
      ) : (
        ""
      )}
      <Select
        labelId={`select-label-${props.labelId}`}
        label={props.selectLabel}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        displayEmpty={props.displayEmpty}
      >
        {props.items.map((item, index) => {
          return (
            <MenuItem
              key={`${props.selectLabel}-menu-${index}`}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export function ReczeeFileInputField(props) {
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      className={
        props.className
          ? `${props.className} ${classes.formControlButton}`
          : classes.formControlButton
      }
      fullWidth={props.fullWidth}
    >
      <input
        accept={props.accept}
        style={{ display: "none" }}
        id={props.fileInputId}
        type="file"
        name={props.name}
        multiple={props.multiple}
        onChange={props.onChange}
      />
      <label htmlFor={props.fileInputId}>
        <Button
          fullWidth={props.fullWidth}
          variant="contained"
          component="span"
          color={props.color || "primary"}
          className={classes.button}
        >
          {props.buttonText}
        </Button>
      </label>
      {props.uploadedFileContent}
    </FormControl>
  );
}

export function ReczeeFileDropInputField(props) {
  const [dropStatus, setDropStatus] = React.useState("undropped");
  const classes = useStyles({ dropStatus: dropStatus });

  React.useEffect(() => {
    setDropStatus("undropped");
  }, []);

  return (
    <>
      {props.title && (
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "normal" }}
          color={props.disabled ? "textSecondary" : "initial"}
        >
          {props.title}
          {props.required && (
            <Typography
              component="span"
              color={props.disabled ? "textSecondary" : "error"}
            >
              *
            </Typography>
          )}
        </Typography>
      )}
      <Dropzone
        onDrop={(acceptedFiles) => {
          props.onChange(acceptedFiles);
          setDropStatus("accepted");
        }}
        onDragEnter={() => {
          setDropStatus("in_progress");
        }}
        accept={{
          "application/pdf": [".pdf"],
          "application/msword": [".doc", ".docx"],
        }}
        onDropAccepted={() => {
          if (props.onDropAccepted) {
            props.onDropAccepted();
          }
          setDropStatus("accepted");
        }}
        onDropRejected={() => {
          if (props.onDropRejected) {
            props.onDropRejected();
          }
          setDropStatus("rejected");
        }}
        disabled={props.disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            className={`${props.className} ${classes.resumeBox}`}
            onDragLeave={() => {
              setDropStatus(false);
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} id={props.fileInputId} />
            <Button
              variant="outlined"
              component="span"
              color="primary"
              size={props.buttonSize}
              className={classes.dropzoneButton}
              disabled={props.disabled}
            >
              {props.buttonText}
            </Button>
            <Typography
              variant="caption"
              className={classes.uploadedFileContent}
            >
              {props.uploadedFileContent}
            </Typography>
            <Typography variant="subtitle2">
              {props.disabled ? (
                <CircularProgress
                  className={classes.resumeProgress}
                  size={16}
                />
              ) : (
                "or drag the files to upload"
              )}
            </Typography>
          </Box>
        )}
      </Dropzone>
    </>
  );
}

export function ReczeeCurrencyPicker(props) {
  const { value, onChange, label, required, disabled, title, className } =
    props;

  return (
    <ReczeeAutoComplete
      single
      fullWidth
      size="small"
      required={required}
      disabled={disabled}
      value={value}
      className={className}
      title={title}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      values={popularCurrencies}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <>
          {label && (
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "normal" }}
              color={params.disabled ? "textSecondary" : "initial"}
            >
              {label}
              {required && (
                <Typography
                  component="span"
                  color={params.disabled ? "textSecondary" : "error"}
                >
                  *
                </Typography>
              )}
            </Typography>
          )}
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select Currency"
          />
        </>
      )}
    />
  );
}
