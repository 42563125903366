import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const getHeroSectionBackground = (color) => {
  return `radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,${color} 68% 70%,#0000 72%) 13px 13px/calc(2*13px) calc(2*13px), radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,${color} 68% 70%,#0000 72%) 13px 13px/calc(2*13px) calc(2*13px), radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,${color} 68% 70%,#0000 72%) 0 0/calc(2*13px) calc(2*13px), radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,${color} 68% 70%,#0000 72%) 0 0/calc(2*13px) calc(2*13px), repeating-conic-gradient(#ffffff 0 25%,#0000 0 50%) 0 0/calc(2*13px) calc(2*13px), radial-gradient(#0000 66%,${color} 68% 70%,#0000 72%) 0 calc(13px/2)/13px 13px #ffffff`;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      textAlign: "center",
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    heroSection: {
      justifyContent: "center !important",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "0 20px",
        boxSizing: "border-box",
      },
    },
    careersPageSection: {
      minHeight: "500px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      alignItems: "center",
    },
    viewAllOpeningsButton: {
      padding: "8px 28px",
      marginTop: theme.spacing(1),
      fontSize: "16px",
      fontWeight: "bolder",
    },
    mainHeading: {
      marginTop: theme.spacing(10),
      fontWeight: "bolder",
      fontSize: "48px",
    },
    benefitsSection: {
      backgroundColor: theme.palette.secondary.main + "04",
    },
    aboutSectionMain: {
      backgroundColor: "#F9F9F9",
      padding: "48px",
      paddingTop: 0,
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        padding: "24px",
      },
    },
    aboutSectionHeading: {
      fontSize: "28px",
      paddingTop: "36px",
      paddingBottom: "14px",
      fontWeight: "700",
      textAlign: "left",
    },
  };
});

export default function PiceCareersPage(props) {
  const classes = useStyles();

  const scrollToOpenPositions = () => {
    const element = document.getElementById("job-openings-list");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div
        className={`${classes.heroSection} ${classes.careersPageSection} `}
        style={{
          background: getHeroSectionBackground(
            props.companyDetails.customStyle.themeSecondaryColor
          ),
        }}
      >
        <Typography component="h1" variant="h4" className={classes.mainHeading}>
          Join our team
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.viewAllOpeningsButton}
          size={"medium"}
          onClick={scrollToOpenPositions}
        >
          View open jobs
        </Button>
      </div>
      <div className={classes.aboutSectionMain}>
        <div className={classes.aboutSection}>
          <Typography
            component="h3"
            variant="h4"
            className={classes.aboutSectionHeading}
          >
            Join Our Team
          </Typography>
          <div>
            At Pice, we're on a mission to revolutionize business payments and
            credit for GST registered businesses in India. With a growing base
            of over 1,00,000+ businesses, we are constantly looking for
            innovative minds to join us on this exciting journey. If you're
            passionate about fintech and eager to make an impact, we want you!
          </div>
        </div>
        <div className={classes.ourVisionSection}>
          <Typography
            component="h3"
            variant="h4"
            className={classes.aboutSectionHeading}
          >
            Our Story
          </Typography>
          <div>
            Founded in September 2021 by a team of visionary entrepreneurs -
            Sandipan Mitra, Gowthami SR, and Aditya Joshi, Pice has quickly
            become a leading name in the financial services sector. With a
            successful funding round of $2.1 million from Nexus Venture Partners
            and 9 angel investors, we are well-positioned to scale and enhance
            our services across India.
          </div>
        </div>
        <div className={classes.peopleAtSection}>
          <Typography
            component="h3"
            variant="h4"
            className={classes.aboutSectionHeading}
          >
            Why Work With Us?
          </Typography>
          <ul style={{ margin: "0px", fontSize: "18px" }}>
            <li>Be a part of a rapidly growing fintech start-up.</li>

            <li>
              Opportunity to innovate and impact the B2B payments landscape.
            </li>
            <li>Engage with cutting-edge technology in financial services.</li>
            <li>
              Contribute to a product that serves a vast and diverse market.
            </li>
          </ul>
        </div>
        <div className={classes.ourVisionSection}>
          <Typography
            component="h3"
            variant="h4"
            className={classes.aboutSectionHeading}
          >
            Our Culture
          </Typography>
          <div>
            Pice is more than just a workplace. We are a team that values
            creativity, collaboration, and commitment. Our culture is built on
            the foundation of mutual respect, continuous learning, and a shared
            passion for excellence. We believe in empowering our employees to
            grow both professionally and personally.
          </div>
        </div>
      </div>
    </>
  );
}
