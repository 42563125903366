import amplitude from "amplitude-js";
export const ACTIVITY_HISTORY_PAGE = "activity_history";
export const REQUISITION_ACTIVITY_HISTORY_PAGE = "requisition_activity_history";
export const DASHBOARD_PAGE = "dashboard";
export const REQUISITION_PAGE = "requisition";
export const NOTIFICATIONS_PAGE = "notifications";
export const SEARCH_PAGE = "search";
export const TALENTHUB_LIST_JOBS_PAGE = "talenthub_all_jobs";
export const CANDIDATE_APPLICATION_PAGE = "candidate_application";
export const ANALYTICS_DASHBOARD_PAGE = "analytics_dashboard";
export const ANALYTICS_METRICS_EXPLORER_PAGE = "analytics_metrics_explorer";
export const VIEW_ANALYTICS_CHART_PAGE = "view_analytics_chart";
export const TALENTHUB_SPECIFIC_JOB_PAGE = "talenthub_specific_job";
export const CANDIDATE_DASHBOARD = "candidate_dashboard";
export const REQUISITION_BULK_IMPORT_PAGE = "requisition_bulk_import";
export const TALENTPOOL_PAGE = "talentpool";
export const LAYOFF_LANDING_PAGE = "layoff_landing";
export const TALENTHUB_COMPANY_PAGE = "talenthub_company_dashboard";
export const SCREENO_DASHBOARD_PAGE = "screeno_dashboard";
export const SCREENO_INTEGRATIONS_PAGE = "screeno_integrations";
export const TALENTHUB_REFERRALS_PAGE = "talenthub_referrals_page";
export const REVIEW_CANDIDATES_PAGE = "review_candidates";
export const TALENTHUB_POST_JOB_PAGE = "talenthub_post_job";
export const JOB_APPLY_PAGE = "job_apply_page";

class AmplitudeLogger {
  constructor() {
    this.apiKey =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_AMPLITUDE_API_KEY
        : process.env.REACT_APP_AMPLITUDE_API_KEY;
    this.enabled = !!this.apiKey;
    console.debug("Amplitude enabled", this.enabled);
  }

  init() {
    if (!this.enabled) return;
    amplitude.getInstance().init(this.apiKey, {
      batchEvents: true,
      eventUploadThreshold: 3,
      eventUploadPeriodMillis: 10 * 1000,
      includeUtm: true,
      includeReferrer: true,
      saveEvents: true,
    });
  }

  startSession(userId, companyId) {
    if (!this.enabled) return;
    amplitude.getInstance().setUserId(userId);
    var identify = new amplitude.Identify();
    identify.set("company_id", companyId);
    amplitude.getInstance().identify(identify);
  }

  existingUserId() {
    return amplitude.getInstance().getUserId();
  }

  endSession() {
    if (!this.enabled) return;
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
  }

  logEvent(eventName, eventProperties) {
    if (this.enabled) {
      amplitude.getInstance().logEvent(eventName, eventProperties);
    } else {
      console.debug("logging amplitude event", eventName, eventProperties);
    }
  }

  isEnabled() {
    return this.enabled;
  }
}

const amplitudeLogger = new AmplitudeLogger();
export default amplitudeLogger;
